.lightroom {
  cursor: pointer;
}

button {
  border-radius: 8px;
  padding: 4px 8px;
  background-color: lightgrey;
  color: black;
  font-weight: 800;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
}

.styles_thumbpanel__1sa4E {
  position: absolute !important;
}

.styles_sub__363ZQ {
  font-size: 1em;
  font-weight: 400;
  padding-bottom: 1em;
  font-family: "Julius Sans One", sans-serif;
  font-style: normal;
}

.styles_lightroomdesc__rNPED a {
  font-family: "Julius Sans One", sans-serif;
  text-decoration: none;
  color: lightpink;
}

.styles_lightroomdesc__rNPED h1 + p {
  font-weight: 600;
  font-family: "Julius Sans One", sans-serif;
  font-style: normal;
}

.styles_lightroomdesc__rNPED h1 + p + p {
  font-style: normal;
}
