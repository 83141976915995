.name-container {
  width: 100%;
  text-align: center;
  transform: scale(0.95);
  animation: scale 5s forwards cubic-bezier(0.5, 1, 0.89, 1);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 2em;
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.name-container span {
  display: inline-block;
  opacity: 0;
  filter: blur(0px);
}

.salon-one span:nth-child(1) {
  animation: fade-in 3s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  font-family: "Hendrickson", "Julius Sans One", sans-serif;
}

.salon-one span:nth-child(2) {
  animation: fade-in 3s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  font-family: "Hendrickson", "Julius Sans One", sans-serif;
}

.salon-one span:nth-child(3) {
  animation: fade-in 3s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
  font-family: "Hendrickson", "Julius Sans One", sans-serif;
}

.salon-one span:nth-child(4) {
  animation: fade-in 3s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
  font-family: "Hendrickson", "Julius Sans One", sans-serif;
}

.salon-one span:nth-child(5) {
  animation: fade-in 3s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
  font-family: "Hendrickson", "Julius Sans One", sans-serif;
}

.salon-one span:nth-child(6) {
  animation: fade-in 3s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  font-family: "Hendrickson", "Julius Sans One", sans-serif;
}

.salon-one span:nth-child(7) {
  animation: fade-in 3s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  font-family: "Hendrickson", "Julius Sans One", sans-serif;
}

.salon-two span:nth-child(1) {
  animation: fade-in 3s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.salon-two span:nth-child(2) {
  animation: fade-in 3s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.salon-two span:nth-child(3) {
  animation: fade-in 3s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.salon-two span:nth-child(4) {
  animation: fade-in 3s 1.11s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.salon-two span:nth-child(5) {
  animation: fade-in 3s 1.13s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.salon-one {
  margin-bottom: -32px;
  font-family: "Hendrickson", "Julius Sans One", sans-serif;
  font-size: 7em;
}

.salon-two {
  font-size: 1em;
  font-family: "Julius Sans One", sans-serif;
}

@media only screen and (max-width: 600px) {
  .name-container {
    height: 100vh;
    justify-content: center;
  }
  .salon-one {
    font-size: 5em;
    margin-bottom: -8px;
    font-family: "Hendrickson", "Julius Sans One", sans-serif;
  }
  .salon-two {
    font-size: 1em;
    font-family: "Julius Sans One", sans-serif;
  }
}
