.navigation-bar {
  height: 4em;
}

.navigation-link {
  position: relative;
  color: white;
  text-decoration: none;
  font-size: 1.1em;
  margin-right: 2em;
  font-family: "Julius Sans One", sans-serif;
}

.navigation-link:hover {
  color: white;
}

.navigation-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.navigation-link:hover::before {
  transform: scaleX(1);
}

@media only screen and (max-width: 600px) {
  .navigation-bar {
    height: auto;
  }
  .navigation-link {
    margin: 0;
  }
}
