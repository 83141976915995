.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.nail {
  margin-bottom: 0;
}

.banner-group {
  background-color: #ebeced;
  padding-top: 4em;
  padding-bottom: 1em;
}

#nail {
  margin-bottom: 0;
}

.salon-services {
  display: flex;
  justify-content: center;
  font-size: 2em;
  height: 20em;
  align-items: center;
  font-family: "Julius Sans One", sans-serif;
  width: 80%;
  margin: 4em auto;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.stylist-heading {
  text-align: center;
  font-family: "Julius Sans One", sans-serif;
  margin-top: 16em;
  background-color: #ebeced;
  padding-top: 4em;
  position: relative;
  margin-bottom: 6em;
  padding-bottom: 4em;
}

.stylist-heading h2 {
  margin-bottom: 4em;
  margin-top: 2em;
  display: flex;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .salon-services {
    font-size: 1.4em;
  }
}
